/* eslint-disable no-undef */
import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Link,
} from 'react-router-dom'
import {
  message,
  Spin,
  Table,
  Button,
  Input,
  Switch,
  Dropdown,
  Menu,
  Modal,
  Icon, 
  Divider,
  Avatar
} from 'antd'
import {
  FacebookShareButton,
} from 'react-share'
import {
  getExamSet,
  editExamSet,
  deleteExamSet,
  shareExamToGroup,
  searchExam,
  getExamSetList,
  onCheckExamSuggestion,
  removeExamSuggestion,
  getGroupNotHaveExam,
  getExamsAll,
  getStudentExamInExamSet
} from '../../redux/exam/action'
import {
  getGroupList,
} from '../../redux/group/action'
import {
  IconCorrect,
  IconExamWarning,
  //IconGroup, 
} from '../IconSVG' 

import CopyIcon from '../IconSVG/Copy.svg'

import CustomIconMath from '../IconSVG/CustomIconMath.svg'
import CustomIconScience from '../IconSVG/CustomIconScience.svg'

import ExerciseIconAddExam from '../IconSVG/ExerciseIconAddExam.svg'
import ExerciseIconScience from '../IconSVG/ExerciseIconScience.svg'
import ExerciseIconMath from '../IconSVG/ExerciseIconMath.svg'
import IconGroup from '../IconSVG/Group.svg'
import IconCustomAddMargin from '../IconSVG/IconCustomAddMargin.svg'
import IconAddCatMargin from '../IconSVG/IconAddCatMargin.svg'
import IconExamSetCompetitionAddMargin from '../IconSVG/IconExamSetCompetitionAddMargin.svg'
import IconExamSetCurriculumAddMargin from '../IconSVG/IconExamSetCurriculumAddMargin.svg'
import IconWarningTestByCodeTesting from '../IconSVG/IconWarningTestByCodeTesting.svg'
import CompetitionScienceIcon from '../IconSVG/CompetitionScienceIcon.svg'
import CompetitionMathIcon from '../IconSVG/CompetitionMathIcon.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import IconExamMath from '../IconSVG/ExamMath.svg'
import MathCATIcon from '../IconSVG/MathCATIcon.svg'
import ScienceCATIcon from '../IconSVG/ScienceCATIcon.svg'
import IconExamScience from '../IconSVG/ExamScience.svg' 
import ModalShareToGroup from './ModalShareToGroup'
import IconStar from '../IconSVG/IconStar.svg'
import IconStarGray from '../IconSVG/IconStarGray.svg'
import Share from '../IconSVG/Share.svg'
import ShareGray from '../IconSVG/ShareGray.svg'
import Delete from '../IconSVG/Delete.svg'
import DeleteGray from '../IconSVG/DeleteGray.svg'
import Facebook from '../IconSVG/Facebook.svg'
import Group from '../IconSVG/Group.svg'
import Copy from '../IconSVG/Copy.svg'
import Report from '../IconSVG/Report.svg'
import ReportGray from '../IconSVG/ReportGray.svg'
import ExamHeader from './ExamHeader'
import ListItems from '../Display/ListItems'
import SearchModal from '../modal/SearchModal'

import styles from './Exam.module.scss'
import { USER_ROLE } from '../../util/config'
import {onBoardCOmponent} from '../OnBoarding/OnBoarding'
import * as moment from 'moment-timezone'
import $ from 'jquery'
import { parseExamType } from '../../util/helper'
// import Autotab from 'react-input-auto-tab'
// dev/ots2023
import ModalUpdatePrivacy from '../modal/ModalUpdatePrivacy'
import ModalPrivacyPolicy from '../Profile/ModalPrivacyPolicy'
import ModalTermCondition from '../Register/ModalTermCondition'
import { AxiosRequest } from '../../redux/fetch'
import { GetProfile } from '../../redux/auth/action'
const API_EDIT_PRIVACYPOLICY = '/auth/privacypolicy'

const { Column } = Table
let arrayCode = ['', '', '', '', '', '', '', '', '', '']
let ctrlDown = false 
class examSet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isShowShareToGroupModal: false,
      examSetToShare: null,
      searchKey: '',
      codeTestingPopup: false,
      codeTesting: [],
      errorMassageSearchByCode: '',
      myExamList: [],
      waitForTour : false,
      otherExamList: [],
      indexRow: null,
      checkStar: null,
      checkMouserOverDelete: null,
      checkShare: null,
      checkReport: false,
      Filters: [],
      FiltersOter:[],
      examList: [],
      groupNotHaveExamList:[],
      sumQuantityOfSearch : 0,
      isSearchModal : false,
      searchResult: [],
      searchResultOther: [],
      searchkeyword: '',
      amountOfExamSearch: 0,
      myExam : {},
      hoverCodeExam: false,
      isLoadingCodeTesting: false,
      showUpdatePrivacyNotice: false,
      showModalPrivacyPolicy: false,
    }
  }

  showSearchModal = () => {
    this.setState({ isSearchModal: !this.state.isSearchModal})
    this.state.isSearchModal && this.onSearchExamSet()
  }

  menu = (examSet) => {  
    return (
      <Menu className={styles.menuExamSetShare} onClick={ ({ domEvent }) => domEvent.stopPropagation() }>
        <Menu.Item key='0'>
          <FacebookShareButton url={`${process.env.REACT_APP_API_URL}/share/fb/${examSet._id}`}>
            <img src={Facebook}/>
            แชร์ Facebook
          </FacebookShareButton>
        </Menu.Item>
        {
          this.props.auth.role !== 'student' && (
            <Menu.Item key='1' onClick={ () => this.onOpenShareToGroup(examSet)}>
              <img src={Group} />
              แชร์เข้ากลุ่ม
            </Menu.Item>
          )
        }
        <Menu.Item key='2'>
          <CopyToClipboard text={`${process.env.REACT_APP_WEB_URL}/examset/${examSet._id}`}
            onCopy={this.onCopyLinkSuccess}
          >
            <div>
              <img src={Copy} />
              คัดลอกลิงก์
            </div>
          </CopyToClipboard>
        </Menu.Item>
      </Menu>
    )
  }

  columns = [
    { title: 'รหัสข้อสอบ',
      dataIndex: 'code',
      key: 'code',
      width: 130,
      render: (code)=> {
        return(<p data-tut-teacher-report='step-report-first'>{code}</p>)
      },
      sorter: (strA, strB) => {
        let icmp = strA.code.toLowerCase().localeCompare(strB.code.toLowerCase())
        if (icmp != 0) {
          return icmp
        }
        if (strA.ownerName > strB.ownerName) {
          return 1
        } else if (strA.ownerName < strB.ownerName) {
          return -1
        } else {
          return 0
        }
      },
      onCell: () => {
        return {
          onMouseEnter: () => { this.setState({ hoverCodeExam: true }) },
          onMouseLeave: () => { this.setState({ hoverCodeExam: false }) }
        }
      }
    },
    {
      title: () => {
        return ( <span style = {{ paddingLeft: '30px' }}>ชื่อ</span> )
      },
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        let logo = null 
        if(record.type === 'G') {
          if(record.subject === 'คณิตศาสตร์') {
            logo = IconExamMath
          }else {
            logo = IconExamScience
          }
        }else if(record.type === 'CAT') {
          if(record.subject === 'คณิตศาสตร์') {
            logo = MathCATIcon
          }else {
            logo = ScienceCATIcon
          }
        }else if(record.type === 'C'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CompetitionMathIcon
          }else {
            logo = CompetitionScienceIcon
          }
        }else if(record.type === 'CUSTOM'){
          if(record.subject === 'คณิตศาสตร์') {
            logo = CustomIconMath
          }else {
            logo = CustomIconScience
          }
        }else {
          if(record.subject === 'คณิตศาสตร์') {
            logo = ExerciseIconMath
          }else {
            logo = ExerciseIconScience
          }
        } 
        return (<span><img src={logo} /> {name}</span>)
      },
      sorter: (strA, strB) => {
        let icmp = strA.name.toLowerCase().localeCompare(strB.name.toLowerCase())
        if (icmp != 0) {
          return icmp
        }
        if (strA.name > strB.name) {
          return 1
        } else if (strA.name < strB.name) {
          return -1
        } else {
          return 0
        }
      }
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      key: 'type',
      render: (type) => <span>{parseExamType(type)}</span>,
      sorter: (strA, strB) => {
        let icmp = strA.type+''.toLowerCase().localeCompare(strB.type+''.toLowerCase())
        if (icmp != 0) {
          return icmp
        }
        if (strA.type > strB.type) {
          return 1
        } else if (strA.type < strB.type) {
          return -1
        } else {
          return 0
        }
      }
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {  
        return (
          <p>{createdAt ? moment.unix(createdAt).add(543, 'Y').format('DD/MM/YYYY') : '-'}</p> 
        )
      },
      sorter: (strA, strB) => {
        let icmp = strA.createdAt+''.toLowerCase().localeCompare(strB.createdAt+''.toLowerCase())
        if (icmp != 0) {
          return icmp
        }
        if (strA.createdAt > strB.createdAt) {
          return 1
        } else if (strA.createdAt < strB.createdAt) {
          return -1
        } else {
          return 0
        }
      }
    },
    {
      // title: 'report',
      render:(report,record,index) => { 
        const { checkReport, indexRow } = this.state
        const { auth } = this.props
        const mouseOver = () => { this.setState({ checkReport: true}) }
        const mouseLeave = () => { this.setState({ checkReport: false}) }  
        return (
          <span onClick={ e => {
            e.stopPropagation() 
            if(auth.role === 'student' && (!record.group || Object.entries(record.group).length === 0) ){ 
              this.props.history.push(`/report/${record._id}`,{ cameFrom: 'exam'})
            }else if((auth.role === 'student' && record.group)){ 
              this.props.history.push(`/report/exam/${record._id}/group/${record.group._id}`,{ cameFrom: 'exam'})
            }else{
              this.props.history.push(`/report/${record._id}/groups`,{ cameFrom: 'exam'})
            }
          }}>
            <img 
              title='รายงาน'
              onMouseOver={mouseOver} 
              onMouseLeave={mouseLeave} 
              src={ checkReport && indexRow === index ? Report : ReportGray } 
              data-tut = 'step-report-first' 
              data-tut-teacher-report='step-report-third'
              style={{ cursor: 'pointer' }}
            />
          </span>
        )},
      width: '30px'
    },
    {
      title: '',
      key: 'action',
      render: (text, examSet,index) => {
        const { auth } = this.props
        const { indexRow,checkShare } = this.state  
        const menuExamSetShare = (
          <Menu className={styles.menuExamSetShare} onClick={ ({ domEvent }) => domEvent.stopPropagation() }>
            <Menu.Item key='0'>
              <FacebookShareButton url={`${process.env.REACT_APP_API_URL}/share/fb/${examSet._id}`}>
                <img src={Facebook} />
                แชร์ Facebook
              </FacebookShareButton>
            </Menu.Item>
            {
              auth.role !== 'student' ?
                // <Menu.Item key='1' onClick={ () => this.onOpenShareToGroup(groupNotHaveExamList)}>
                <Menu.Item key='1' onClick={ () => this.onOpenShareToGroup(examSet)}>
                  <img src={IconGroup}
                  />
                  แชร์เข้ากลุ่ม
                </Menu.Item>
                : ''
            }
            <Menu.Item key='2'>
              <CopyToClipboard text={`${window.location.host}/examset/${examSet._id}`}
                onCopy={this.onCopyLinkSuccess}
              >
                <div>
                  {/* <IconCopy style={{minWidth: 12, marginRight: 8}}/> */}
                  <Avatar src={CopyIcon} size={26}/>
                  คัดลอกลิงก์
                </div>
              </CopyToClipboard>
            </Menu.Item>
          </Menu>
        )
        const mouseOver = () => { this.setState({ checkShare: true}) }
        const mouseLeave = () => { this.setState({ checkShare: false}) } 
        return (
          <Dropdown overlay={menuExamSetShare} trigger={['click']} onClick={ e => e.stopPropagation() } title='แชร์'>
            <img  title='แชร์' onMouseOver={mouseOver} onMouseLeave={mouseLeave} src={ checkShare && indexRow === index ? Share : ShareGray} style={ examSet.group ? { display: 'none',cursor: 'pointer' } : { display: 'unset',cursor: 'pointer' }} data-tut = 'step-report-second' data-tut-teacher-report='step-report-second'/>
          </Dropdown>
        )
      },
      width: '30px'
    },
    {
      // title: '555',
      render:(deleteExam,record,index) => {
        const { checkMouserOverDelete, indexRow } = this.state 
        const mouserOver = () => {
          this.setState({ checkMouserOverDelete: true })
        }
        const mouseLeave = () => {
          this.setState({ checkMouserOverDelete: false})
        }
        return (
          <span onClick={ e => { e.stopPropagation();this.onDeleteExamSet(record) }} title='ลบข้อสอบ'>
            <img onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkMouserOverDelete && indexRow === index ? Delete : DeleteGray} style={{ cursor: 'pointer' }}/>
          </span>
        )
      },
      // width: '30px'
    }
  ]

  code = ['','','','','','','','','','']
  componentDidMount = () => {
    if (this.props.auth.role === USER_ROLE.STUDENT) {
      this.columns.splice(3,0,{
        title: 'กลุ่ม',
        dataIndex: 'group',
        key: 'group',
        render: (group ) => { 
          return (<span>{group ? group.name : '-'}</span>)
        }, 
        sorter: (strA, strB) => {
          let icmp = strA.group ? strA.group.name+''.toLowerCase().localeCompare(strB.group ? strB.group.name+''.toLowerCase() : '') : ''
          if (icmp != 0) {
            return icmp
          }
          if (strA.group ? strA.group.name : '' > strA.group ? strB.group.name : '') {
            return 1
          } else if (strA.group ? strA.group.name : '' < strA.group ? strB.group.name : '') {
            return -1
          } else {
            return 0
          }
        },
      },)
      this.columns.splice(4, 0, {
        title: 'จัดโดย',
        dataIndex: 'owner',
        key: 'owner',
        render: (owner) => {
          return (<span>{owner.firstName} {owner.lastName}</span>)
        },
        sorter: (strA, strB) => {
          let icmp = strA.owner.firstName+''.toLowerCase().localeCompare(strB.owner.firstName+''.toLowerCase())
          if (icmp != 0) {
            return icmp
          }
          if (strA.owner.firstName > strB.owner.firstName) {
            return 1
          } else if (strA.owner.firstName < strB.owner.firstName) {
            return -1
          } else {
            return 0
          }
        }
      },)
      this.columns.splice(5, 0, {
        title: '',
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => {    
          let text = 'เริ่มทำ'  
          if(record.startedAt) {
            if(record.finishedAt) {
              text = 'ทำซ้ำ'
            }else {
              text = 'ทำต่อ'
            }
            if(record.type === 'CAT') return ''
          } 
          if(status === 'close' || record.deletedAt) {
            return ''
          } else {
            return (record.oneTimeDone && status === 'finished') ? '' : <Button  className={ styles.button_start_exam } >{text}</Button>
          }
        }
      },)
    }else{
      this.columns.splice(3, 0, {
        title: 'จัดโดย',
        dataIndex: 'owner',
        key: 'owner',
        render: (owner) => {
          return (<span>{owner.firstName} {owner.lastName}</span>)
        }
      },)
      this.columns.splice(4,0,{
        title: 'สถานะ',
        dataIndex: 'status',
        key: 'status',
        render: (status, examSet) => <Switch checkedChildren='เปิด' unCheckedChildren='ปิด' checked={status === 'true' || status === true} onChange={ (checked, e) => {
          e.stopPropagation()
          this.onChangeExamStatus(examSet._id, checked)
        } } />
      })
    }
    this.fetchExamSet()
    this.fetchExamSetList()

    // dev/ots2023
    this.setState({
      showUpdatePrivacyNotice: !this.props.auth.hasSeenPrivacyPolicy,
    })
  }
  // onPaginate = (page) => {
  //   this.fetchExamSetList(this.state.searchkeyword, page.current ? page.current : page) 
  // }
  fetchExamSetList = async(filter = '',page = '') => { 
    // localStorage.setItem('numOfExam',-1)
    this.setState({ isLoading: true })
    const { getExamSetList } = this.props
    const { checkEnter } = this.state

    const res = await getExamSetList(filter,page)
    const resDefault = await getExamSetList(filter,page)
    
    // const resOther = res
    // const otherExamSet = resOther.items ? resOther.items.filter(my => my.owner._id.indexOf(this.props.auth.id) == -1) : []
    // resOther['items'] = otherExamSet

    if(res.error){
      message.error(res.error)
    }
    if(res.items && res.items.length > 0){
      localStorage.setItem('numOfExam',res.items.length)
    }

    await this.setState({ 
      amountOfExamSearch: res ? res.totalCount : 0,
      myExamList: checkEnter ? res : resDefault, 
      // searchResult : checkEnter ? res : {},
      // searchResultOther : this.state.checkEnter ? res : {},
      waitForTour : true,
      isLoading: false, 
    })
  }

  fetchExamSet = async (filter = null) => {
    this.setState({ isLoading: true })
    // const res = await this.props.getExamSet(filter)
    const res = filter ? await this.props.getGroupNotHaveExam(filter._id) : await this.props.getGroupList(filter)
    this.setState({ groupNotHaveExamList: res, isLoading: false })
    if (res.error) message.error(res.error)
  }
  fetchGroups = async (exam) => {
    this.setState({ isLoading: true })
    const res = await this.props.getGroupNotHaveExam(exam._id)
    this.setState({ groupNotHaveExamList:res,  isLoading: false })
    if (res.error) message.error(res.error)
  }
  // fetchGroups = async (filter = null) => {
  //   this.setState({ isLoading: true })
  //   const res = await this.props.getGroupNotHaveExam(filter)
  //   this.setState({ groupNotHaveExamList:res, isLoading: false })
  //   if (res.error) message.error(res.error)
  // }
  onSearchExamSet = async(value) => {
    let { searchResult, searchResultOther } = this.state 
    const { auth } = this.props
    let { myExamList, otherExamList ,FiltersOter, sumQuantityOfSearch} = this.state
    let isEmptySearch = true
    this.setState({
      checkEnter : true,
      searchkeyword: value
    })
    const res = await this.props.getExamSetList(value,1)
    if(auth.role === 'admin'){ 
      if (value) {
        isEmptySearch = false
        sumQuantityOfSearch = res.totalCount
      }
      if(!isEmptySearch) {
        myExamList = res
        searchResult = res
        otherExamList.items = FiltersOter
        await this.setState({
          myExamList,
          searchResult,
          otherExamList,
          sumQuantityOfSearch
        })
      }else{
        await this.fetchExamSetList()
      }
    }
    else{
      if (value) {
        const res = await this.props.getExamSetList(value,1)
        const resOther = await this.props.getExamSetList(value,1)

        const myExamSet = res.items ? res.items.filter(my => my.owner._id.indexOf(this.props.auth.id) > -1) : []
        res['items'] = myExamSet
        
        const otherExamSet = resOther.items ? resOther.items.filter(my => my.owner._id.indexOf(this.props.auth.id) == -1) : []
        resOther['items'] = otherExamSet
        
        isEmptySearch = false
        
        sumQuantityOfSearch = res ? res.totalCount : 0

        if(!isEmptySearch) {
          myExamList = res
          otherExamList = resOther
          searchResult = res
          searchResultOther = resOther
          await this.setState({
            myExamList,
            otherExamList,
            searchResult,
            searchResultOther,
            sumQuantityOfSearch
          })
        }else{
          await this.fetchExamSetList()
        }
      }
    }
  }

  onSearchGroup = (value) => {
    let { groupNotHaveExamList,examSetToShare } = this.state
    let isEmptySearch = true
    if(value && value !== [] && value !== ''){
      isEmptySearch = false
      groupNotHaveExamList = groupNotHaveExamList.filter(group => group.name.toLowerCase().indexOf(value.toLowerCase()) > -1 || group.code === value)
    }
    if(!isEmptySearch){
      this.setState({ groupNotHaveExamList })
    }else{
      this.fetchGroups(examSetToShare)
    }
  }

  onChangeExamStatus = async(examId, status) => {
    await this.props.editExamSet(examId, {status})
    await this.fetchExamSetList()
  }

  onDeleteExamSet = (examSet) => { 
    const modal = Modal.confirm({
      centered: true,
      // okText: 'ลบข้อสอบ',
      // cancelText: 'ไม่ลบ',
      className: styles.modal_delete_exam,
      width: 580,
      icon: null,
      footer: null, 
      title: (
        <button aria-label='Close' className='ant-modal-close' style={{display: 'block'}} onClick={()=> modal.destroy()}>
          <Icon type='close' className='ant-modal-close-x'/>
        </button>
      ),
      content: (
        <div style={{textAlign: 'center'}}>
          <IconExamWarning style={{fontSize: 256, marginTop: -30, marginBottom: -10}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>ลบข้อสอบ</h2>
          <p style={{marginBottom: 0}}>{examSet.name}</p>
          <p className='text-thin text-sm' style={{color: '#3D3D3D', opacity: 0.5}}>{examSet.code}</p>
          <p>คุณต้องการลบชุดข้อสอบนี้หรือไม่</p>
          <div className={styles.modalDeleteExam} >
            <div className={styles.sub_modalPauseExam} ><Button onClick = {e => {
              e.stopPropagation()
              Modal.destroyAll()
            }}>ไม่ลบ</Button></div>
            <div className={styles.sub_modalPauseExam_2} ><Button type = 'primary' onClick = { async() => {
              let res = []
              if(examSet.group && examSet.group._id) {
                res = await this.props.deleteExamSet(examSet._id,examSet.group._id)
              }else {
                res = await this.props.deleteExamSet(examSet._id)
              }
              Modal.destroyAll()
              if (res.error) return message.error('พบข้อผิดพลาดในการลบชุดข้อสอบนี้') 
              await this.fetchExamSetList() 
              message.success('ลบข้อสอบสำเร็จ') 
            }}>ลบข้อสอบ</Button></div>
          </div>
        </div>
      ),
    })
  }

  onCopyLinkSuccess = () => {
    Modal.success({
      closable: true,
      centered: true,
      icon: null,
      maskClosable: true,
      okButtonProps: {hidden: true},
      width: 220,
      content: (
        <div style={{textAlign: 'center'}}>
          <IconCorrect style={{fontSize: 120}}/>
          <h2 className='text-medium' style={{fontSize: 20}}>คัดลอกลิงก์สำเร็จ</h2>
        </div>
      )
    })
  }

  onOpenShareToGroup = async (examSet) => {
    await this.fetchGroups(examSet)
    this.setState({
      isShowShareToGroupModal: true,
      examSetToShare: examSet,
    })
  }

  onCloseModalShareToGroup = () => this.setState({isShowShareToGroupModal: false, examSetToShare: null})

  onSubmitShareToGroup = async (id, selectGroupList) => {
    this.setState({ isLoading: true })
    let data = { groupIds: selectGroupList }
    const res = await this.props.shareExamToGroup(id, data)
    this.setState({ isLoading: false })
    if (res.error) return message.error(res.error)
    message.success('เพิ่มข้อสอบลงกลุ่มสำเร็จ')
    this.onCloseModalShareToGroup()
  }

  setSearchKey = async(e) => {
    this.setState({ searchKey: e})
    if(e === '' && this.state.checkEnter === true){
      await this.fetchExamSetList()
      this.setState({
        checkEnter : false,
        sumQuantityOfSearch : 0
      })
    }
  }

  onCodeTestingPopup = () => {
    let {codeTestingPopup} = this.state
    this.setState({ codeTestingPopup: !codeTestingPopup })
  }
  onSearchExamByCode = async() => {  
    this.setState({ isLoadingCodeTesting: true })
    if(arrayCode.length > 10) { 
      arrayCode = arrayCode.slice(0,10)
    }
    const res = await this.props.searchExam(arrayCode.join('').toLowerCase()) 
    if(res.error || arrayCode.length < 10){
      this.setState({ errorMassageSearchByCode: 'รหัสข้อสอบไม่ถูกต้อง'})
      arrayCode = []
    }else{
      await this.props.history.push(`/examset/${res._id}`)
      arrayCode = []
    }
    this.setState({ isLoadingCodeTesting: false })
    // res.error || arrayCode.length < 10 ? this.setState({ errorMassageSearchByCode: 'รหัสข้อสอบไม่ถูกต้อง'}) : await this.props.history.push(`/examset/${res._id}`)
  }

  onChangeInput = async (index, e) => {
    let { codeTesting } = this.state

    codeTesting[index] = e.target.value
    this.setState({ codeTesting })
  }

  checkExamSuggestion = async(exam) => {
    const { indexRow } = this.state
    const { onCheckExamSuggestion } = this.props

    this.setState({ isLoading: true })

    await onCheckExamSuggestion(exam[indexRow]._id)
    await this.fetchExamSetList()

    message.success('เพิ่มข้อสอบแนะนำสำเร็จ')
    this.setState({ isLoading: false })
  }

  onRemoveExamSuggestion = async(exam) => {
    const { removeExamSuggestion } = this.props

    this.setState({ isLoading: true })

    await removeExamSuggestion(exam._id)
    await this.fetchExamSetList()

    message.success('ยกเลิกข้อสอบแนะนำสำเร็จ')
    this.setState({ isLoading: false })
  }

  switchExamShow = async(exam)=> {
    const { editExamSet } = this.props
    let data = { status: !exam.status }

    this.setState({ isLoading: true })

    await editExamSet(exam._id,data)
    await this.fetchExamSetList()

    this.setState({ isLoading: false })
  }
  clearSearch = async() => {
    let { searchKey } = this.state
    searchKey = ''
    await this.setState({
      searchKey,
      checkEnter : false,
      sumQuantityOfSearch : 0
    })
    await this.fetchExamSetList()
  }

  listItem = (array) => {
    const { auth } = this.props  
    const arr = array.items || []    
    
    const srcData = arr.map((item ) => {  
      const status = item.status 
      const menu = [ 
        {
          attribute: (
            <span style={{ display: 'block' }}
              onClick={e => {
                e.stopPropagation()
                auth.role === 'student' ? this.props.history.push(`/report/${item._id}`,{ cameFrom: 'exam'}) : this.props.history.push(`/report/${item._id}/groups`,{ cameFrom: 'exam'})
              }}
            >
              <img src={Report}/> รายงาน
            </span> 
          )
        } ,
        {
          attribute: (
            <FacebookShareButton url={`${process.env.REACT_APP_API_URL}/share/fb/${item._id}`}>
              <img src={Facebook} style={{ paddingRight: '6px' }} />
            แชร์ Facebook
            </FacebookShareButton>
          )
        }, 
        {
          attribute: (
            <span style={{ display: 'block' }}
              onClick={e => {
                e.stopPropagation()
                this.onOpenShareToGroup(item)
              }}
            >
              <img src={Group} /> แชร์เข้ากลุ่ม
            </span>
          )
        },
        {
          attribute: (
            <CopyToClipboard text={`${process.env.REACT_APP_WEB_URL}/examset/${item._id}`}
              onCopy={this.onCopyLinkSuccess}
            >
              <div>
                <img src={Copy} style={{ paddingRight: '6px' }}/>
              คัดลอกลิงก์
              </div>
            </CopyToClipboard>
          )
        },
        {
          attribute: (
            <span style={{ display: 'block' }}
              onClick={e => {
                e.stopPropagation()
                this.onDeleteExamSet(item)
              }}
            >
              <img src={Delete} /> ลบ
            </span>
          )
        },
        {
          attribute: (
            <span>
              สถานะ
              <Switch
                checkedChildren='เปิด'
                unCheckedChildren='ปิด'
                checked={status === 'true' || status === true}
                onChange={(checked, e) => {
                  e.stopPropagation()
                  this.switchExamShow(item)
                }}
                className={styles.switch_exam}
                style={{ float: 'right' }}
              />
            </span>
          )
        },
      ]

      const menuStudent =  [
        {
          attribute: (
            <span style={{ display: 'block' }}
              onClick={e => {
                e.stopPropagation()
                this.props.history.push(`/report/${item._id}`,{ cameFrom: 'exam' })
              }}
            >
              <img src={Report}/> รายงาน 
            </span>
          )
        },
        {
          attribute: (
            //examSet.group ? `${process.env.REACT_APP_WEB_URL}/examset/${examSet._id}/group/${examSet.group._id}` : `${process.env.REACT_APP_WEB_URL}/examset/${examSet._id}`
            <FacebookShareButton url={`${process.env.REACT_APP_API_URL}/share/fb/${item._id}`}>
              <img src={Facebook} style={{ paddingRight: '6px' }} />
            แชร์ Facebook
            </FacebookShareButton>
          )
        },  
        {
          attribute: (
            <CopyToClipboard text={`${process.env.REACT_APP_WEB_URL}/examset/${examSet._id}`}
              onCopy={this.onCopyLinkSuccess}
            >
              <div>
                <img src={Copy} style={{ paddingRight: '6px' }}/>
              คัดลอกลิงก์
              </div>
            </CopyToClipboard>
          )
        },
        {
          attribute: (
            <span style={{ display: 'block' }}
              onClick={e => {
                e.stopPropagation()
                this.onDeleteExamSet(item)
              }}
            >
              <img src={Delete} /> ลบ
            </span>
          )
        }, 
      ] 
      let logo = null
      if(item.type === 'G') {
        if(item.subject === 'คณิตศาสตร์') {
          logo = IconExamMath
        }else {
          logo = IconExamScience
        }
      }else if(item.type === 'CAT') {
        if(item.subject === 'คณิตศาสตร์') {
          logo = MathCATIcon
        }else {
          logo = ScienceCATIcon
        }
      }else{
        if(item.subject === 'คณิตศาสตร์') {
          logo = CompetitionMathIcon
        }else {
          logo = CompetitionScienceIcon
        }
      }
      return {
        avatar: logo,
        title: item.name,
        description: item.code,
        status: `วันที่สร้าง: ${moment.unix(item.createdAt).add(543, 'Y').format('DD/MM/YYYY')}`,
        actions: auth.role === 'student' ? menuStudent : menu,
        path: `/examset/${item._id}`,  
      }
    })
    srcData['page'] = array.page
    srcData['lastPage'] = array.lastPage
    srcData['totalCount'] =array.totalCount
    return srcData
  }

  onCloseModalUpdateUserInfo = (e) => {
    const { auth } = this.props
    if (auth.role == USER_ROLE.STUDENT) {
      this.props.history.push('/profile')
    }
    else {
      this.setState({
        showUpdatePrivacyNotice: false,
        showModalPrivacyPolicy: true,
      })
    }
  }

  setPrivacyPolicy = (privacyPolicy) => {  
    this.setState({ privacyPolicy: privacyPolicy.target.checked })
  }

  onCancelModalPrivacyPolicy = () => {
    this.setState({
      showModalPrivacyPolicy: false
    })
  }

  onSaveModalPrivacyPolicy = async () => {
    // console.log(this.state.privacyPolicy)
    const values = {privacyPolicy: this.state.privacyPolicy}
    this.setState({ isLoading: true })
    const res = await AxiosRequest(API_EDIT_PRIVACYPOLICY, 'patch', values)
    this.setState({ isLoading: false })
    if (res.error) {
      return message.error('ไม่สามารถบันทึกการยอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคลได้')
    }
    await this.props.GetProfile()
    message.success('บันทึกการยอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคลสำเร็จ')
    this.setState({
      showModalPrivacyPolicy: false
    })
    this.props.history.push('/examset')
  }


  render() {
    const { auth } = this.props
    const {codeTestingPopup, errorMassageSearchByCode, myExamList, otherExamList, searchKey, checkEnter, sumQuantityOfSearch, isLoading } = this.state
    const { showModalPrivacyPolicy, showUpdatePrivacyNotice } = this.state
    const { dataPrivacy, termAndCondition } = this.props
    const elemRefs = []
    const showWelcome = (!auth.hasSeenPrivacyPolicy && auth.isImportJoin && !auth.isSeenTermAndCondition)
    const disabledLink = (auth.role === USER_ROLE.TEACHER ? 'none' : 'auth')
    const disabledLinkColor = (auth.role === USER_ROLE.TEACHER ? '#BFBFBF' : '#000000')

    const script = document.getElementById('recaptchaScript')
    if (script) {
      // console.log('remove script')
      script.remove()
    }
    
    const badge = document.getElementsByClassName('grecaptcha-badge')
    if (badge.length > 0) {
      // console.log('remove badge')
      badge[0].remove()
    }
    // const style = document.createElement('style')
    // style.innerHTML = '.grecaptcha-badge { visibility: hidden !important; }'
    // style.id = 'hideRecaptchaBadge'
    // document.head.appendChild(style)

    const menuExamSetCreate = (
      <Menu className={styles.menuExamSetCreate}>
        <Menu.Item key='0' 
          data-tut = 'StudentCreateExamstepTwo'
          data-tut-teacher = 'TeacherCreateExamstepSecond'
          id={'curriculum'}
          onClick = {()=>{setTimeout(()=>{this.props.nextStepTour()},100)}}
        >
          <Link to='/examset/create/curriculum'>
            <img src={IconExamSetCurriculumAddMargin} />
            <span style={{marginLeft: '5px'}}>ตามหลักสูตร</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='1' id={'competition'}>
          <Link to='/examset/create/competition'>
            <img src={IconExamSetCompetitionAddMargin} hello='world'/>
            <span style={{marginLeft: '5px'}}>แข่งขัน</span>
          </Link>
        </Menu.Item>
        {
          auth.role === USER_ROLE.STUDENT ?
            (<Menu.Item key='2' id={'cat'}>
              <Link to='/examset/create/cat'>
                <img src={IconAddCatMargin} />
                <span style={{marginLeft: '5px'}}>CAT</span>
              </Link>
            </Menu.Item>)
            : null
        }
        {
          auth.role === USER_ROLE.SUPER_TEACHER || auth.role === USER_ROLE.ADMIN ?
            (<Menu.Item key={auth.role === USER_ROLE.ADMIN ? 3 : 2} id={'custom'}>
              <Link to='/examset/create/custom'>
                <img src={IconCustomAddMargin}/>
                <span style={{marginLeft: '5px'}}>กำหนดเอง</span>
              </Link>
            </Menu.Item>)
            : null
        }
        {
          //ALLOW any same as curriculum
          auth.role !== USER_ROLE.STUDENT ?
            (<Menu.Item key='99' id={'curriculumbook'} disabled={auth.role == USER_ROLE.TEACHER}>
              <Link to='/examset/create/curriculumbook' style={{ pointerEvents: disabledLink, color: disabledLinkColor }}>
                <img src={ExerciseIconAddExam}/>
                <span style={{marginLeft: '5px'}}>แบบฝึกหัด</span>
              </Link>
            </Menu.Item>)
            : null
        }
        {/* {
          auth.role === USER_ROLE.SUPER_TEACHER || auth.role === USER_ROLE.ADMIN ?
            (<Menu.Item key={auth.role === USER_ROLE.ADMIN ? 3 : 2} id={'exercise'}>
              <Link to='/examset/create/exercise'>
                <img src={ExerciseIconAddExam}/>
                <span style={{marginLeft: '5px'}}>แบบฝึกหัด</span>
              </Link>
            </Menu.Item>)
            : null
        } */}
      </Menu>
    )  
    const autoTab = async(e,isPasted = false, index) => { 
      const BACKSPACE_KEY = 8
      const DELETE_KEY = 46
      const SHIFT_KEY = 16
      const LEFT_ARROW = 37 
      const command = 91
      const control = 17
      let value = e.target.value 
      const lengthStr = e.target.value.length  
      if(e.keyCode === BACKSPACE_KEY) { 
        arrayCode.pop()
      }  
      if(isPasted) {    
        let str = ''  
        setTimeout(async() => {
          for(let i = 0; i < lengthStr; i++) { 
            str = value.slice(0,1) 
            document.getElementById(`input${i}`).value = str
            value = value.slice(1,value.length) 
            arrayCode[i] = str
            let tabindex = $(e.target).attr('data-index') || 0
            let elem = null 
            tabindex = Number(tabindex)  
            elem = tabindex < elemRefs.length - 1 && elemRefs[tabindex + 1]  
            if(tabindex > 9) await elem.current.focus() 
          } 
        },250) 
        
      } else {
        let newData = e.target.value
        if (lengthStr > 1) {
          if (!arrayCode[index]) {
            newData = newData[0]
          }else if(newData[0] === newData[1]) {
            newData = newData[1]
          }else {
            newData = e.target.value.replace(arrayCode[index], '')  
          } 
        } 
        arrayCode[index] = newData 
        document.getElementById(`input${index}`).value = newData
        let tabindex = $(e.target).attr('data-index') || 0 
        let elem = null 
        tabindex = Number(tabindex)   
        if ((e.keyCode === LEFT_ARROW) && (e.keyCode !== command || e.keyCode !== control || e.keyCode !== BACKSPACE_KEY)) { 
          elem = tabindex > 0 && elemRefs[tabindex - 1] 
          arrayCode.pop()
        } else if ((e.keyCode !== DELETE_KEY && e.keyCode !== SHIFT_KEY && e.keyCode !== command && e.keyCode !== control && e.keyCode !== BACKSPACE_KEY)) {
          elem = tabindex < elemRefs.length - 1 && elemRefs[tabindex + 1]
        } 
        if (elem) { 
          elem.current.focus() 
        }
      } 
    }

    const InputBlock = (props) => {
      const ref = React.createRef() 
      elemRefs.push(ref) 
      let ctrlKey = 17, cmdKey = 91 
      return (
        <Input
          className = { styles.input_block }
          id = { props.id }
          data-index = { props.index }
          ref = { ref }
          maxLength = { 10 } 
          onKeyUp = {(e) => {      
            if(!ctrlDown) { 
              props.autoTab(e,false,props.index)
            }  
            ctrlDown = false 
          }} 
          onKeyDown = {(e) => {   
            if(e.keyCode === ctrlKey || e.keyCode === cmdKey) {
              ctrlDown = true  
            }
          }}
          onPaste = {(e) => {  
            e.persist() 
            setTimeout(() => {
              props.autoTab(e, true,props.index)   
            },0)
          }}
          onPressEnter = { this.onSearchExamByCode }
        />
      )
    }

    const block = Array.from({ length: 10 }, (element, index) => {
      return (
        <InputBlock key={index} index={index} autoTab={autoTab} id={`input${index}`}/>
      )
    })

    const { hoverCodeExam } = this.state
    const examCollection = this.listItem(myExamList)  
    const searchResult = this.listItem(this.state.searchResult)
    const searchResultOther = this.listItem(this.state.searchResultOther)   
    return (
      <div>
        {
          this.props.auth.isSeenTutorial === true ?
            <div>
              {
                onBoardCOmponent
              }
            </div>
            :
            <Fragment>
            <div className={styles.examSetDiv}>
              <Spin size='large' tip='Loading...' spinning={ isLoading } >
                <ExamHeader
                  isTour = {true}
                  search
                  code
                  manage
                  nextStepTour = {this.props.nextStepTour}
                  numOfStep = {this.props.numOfStep}
                  title='ข้อสอบ/แบบฝึกหัด'
                  searchKey={searchKey}
                  onSearchExamSet={this.onSearchExamSet}
                  setSearchKey = {this.setSearchKey}
                  menuExamSetCreate={menuExamSetCreate}
                  onCodeTestingPopup={this.onCodeTestingPopup}
                  role={auth.role}
                  showSearchModal={this.showSearchModal}
                />
                <div className={styles.examDetail}>
                  <div className={styles.examSetMobile}>
                    <ListItems
                      srcData={examCollection}
                      avatar
                      status
                      // onPaginate = {this.onPaginate}
                      history={this.props.history}
                      dropdownDivider={true}
                      dropdownWidth={'200px'} 
                    />
                  </div>

                  <div className={styles.examSetDesktop}>
                    {
                      auth.role === 'admin' ?
                        <div>
                          {
                            checkEnter ? 
                              <div>
                                <div className = {styles.div_result_title}>
                                  {/* <span className = {styles.title_result_search}>ผลการค้นหา "{searchKey}" {sumQuantityOfSearch} รายการ </span> */}
                                  <span className = {styles.title_result_search}>{`ผลการค้นหา "${searchKey} ${sumQuantityOfSearch} รายการ"`}</span>

                                  <a className = {styles.clear_result} style ={{color : '#333333'}} onClick={()=>this.clearSearch()}>เคลียร์การค้นหา</a>
                                </div>
                                <h2 className = {styles.title_myexam}>ข้อสอบของฉัน</h2>
                                <Divider/>
                              </div>
                              :
                              ''
                          } 
                          <Table rowKey = { (record,i) => i}
                            scroll={{x:true}}
                            dataSource={myExamList.items}
                            pagination={{ simple: true, defaultCurrent: 1, position: 'both'}}
                            // onChange = {(pagination) => { this.onPaginate(pagination) }}
                            onRow = {(record, rowIndex) => {
                              const { hoverCodeExam } = this.state  
                              return {
                                onMouseEnter: () => { this.setState({ indexRow: rowIndex}) },
                                onClick: () => { if(!hoverCodeExam) this.props.history.push(`/examset/${record._id}`) }
                              }
                            }}
                            className={styles.table_admin}
                          >
                            <Column className={styles.suggest} title='ข้อสอบแนะนำ' dataIndex='isSuggestion'
                              align='center' render={(isSuggestion,record,index) => {
                                const { checkStar,indexRow } = this.state
                                const mouseOver = () => { this.setState({ checkStar: true }) }
                                const mouserLeave = () => { this.setState({ checkStar: false }) }
                                return (
                                  <img title={'เลือกเป็นข้อสอบแนะนำ'} onClick={e => {
                                    e.stopPropagation()
                                    isSuggestion ? this.onRemoveExamSuggestion(record) : this.checkExamSuggestion(myExamList.items)
                                  }}
                                  onMouseOver={mouseOver} onMouseLeave={mouserLeave}
                                  src={ isSuggestion? IconStar : (checkStar && indexRow === index ? IconStar :IconStarGray) }
                                  style={{ cursor: 'pointer' }}
                                  />
                                )
                              }}/>
                            <Column className={styles.code} title='รหัสข้อสอบ' dataIndex='code' 
                              onCell={() => {
                                return {
                                  onMouseOver: () => { this.setState({ hoverCodeExam: true }) },
                                  onMouseLeave: () => { this.setState({ hoverCodeExam: false }) }
                                }
                              }}
                              sorter={(strA, strB) => { 
                                let icmp = strA.code.toLowerCase().localeCompare(strB.code.toLowerCase())
                                if (icmp != 0) { 
                                  return icmp
                                } 
                                if (strA.code > strB.code) {
                                  return 1
                                } else if (strA.code < strB.code) {
                                  return -1
                                } else {
                                  return 0
                                }}}
                            />
                            <Column className={styles.name}
                              title={() => {
                                return ( <span style={{paddingLeft: '20px'}}>ชื่อ</span> )
                              }} dataIndex='name' render={ (name,record) => {
                                let logo = null
                                if(record.type === 'G') {
                                  if(record.subject === 'คณิตศาสตร์') {
                                    logo = IconExamMath
                                  }else {
                                    logo = IconExamScience
                                  }
                                } else if(record.type === 'C'){
                                  if(record.subject === 'คณิตศาสตร์') {
                                    logo = CompetitionMathIcon
                                  }else {
                                    logo = CompetitionScienceIcon
                                  }
                                }else if(record.type === 'CUSTOM'){
                                  if(record.subject === 'คณิตศาสตร์') {
                                    logo = CustomIconMath
                                  }else {
                                    logo = CustomIconScience
                                  }
                                }else {
                                  if(record.subject === 'คณิตศาสตร์') {
                                    logo = ExerciseIconMath
                                  }else {
                                    logo = ExerciseIconScience
                                  }
                                }
                                
                                return (
                                  <div> <img src={ logo }  /> {name} </div>
                                )
                              }}
                              sorter={(strA, strB) => { 
                                let icmp = strA.name.toLowerCase().localeCompare(strB.name.toLowerCase())
                                if (icmp != 0) { 
                                  return icmp
                                } 
                                if (strA.name > strB.name) {
                                  return 1
                                } else if (strA.name < strB.name) {
                                  return -1
                                } else {
                                  return 0
                                }}}
                            />
                            <Column className={styles.type} title='ประเภท' dataIndex='type' render = {(type) => {
                              return (
                                <span>{ type === 'G' ? 'ตามหลักสูตร' : type === 'C' ? 'แข่งขัน' : type === 'CAT' ? 'CAT' : (type === 'EX' || type === 'E') ? 'แบบฝึกหัด' : 'กำหนดเอง' }</span>
                              )
                            }}
                            sorter={(strA, strB) => { 
                              let icmp = strA.type.toLowerCase().localeCompare(strB.type.toLowerCase())
                              if (icmp != 0) { 
                                return icmp
                              } 
                              if (strA.type > strB.type) {
                                return 1
                              } else if (strA.type < strB.type) {
                                return -1
                              } else {
                                return 0
                              }}}
                            />
                            <Column className={styles.counting} title= {() => {
                              return (
                                <span>
                            จำนวนครั้งที่ทำ
                                </span>
                              )
                            }} dataIndex = 'countTestings' align='center'
                            sorter={(a,b) => a.countTestings - b.countTestings}
                            />
                            {/* <Column className={styles.createdBy} title = 'จัดโดย' dataIndex = 'owner' render = {(owner) => {
                              return (
                                <span>{owner.firstName+' '+owner.lastName}</span>
                              )
                            }}
                            sorter={(strA, strB) => { 
                              let icmp = strA.createdBy.toLowerCase().localeCompare(strB.createdBy.toLowerCase())
                              if (icmp != 0) { 
                                return icmp
                              } 
                              if (strA.createdBy > strB.createdBy) {
                                return 1
                              } else if (strA.createdBy < strB.createdBy) {
                                return -1
                              } else {
                                return 0
                              }}}
                            /> */}
                            <Column className={styles.createdAt} title='วันที่สร้าง' dataIndex='createdAt' render={(createdAt) => {
                              return (
                                <span>{createdAt ? moment.unix(createdAt).add(543, 'Y').format('DD/MM/YYYY') : '-'}</span>
                              )
                            }}
                            sorter={(strA, strB) => { 
                              let icmp = strA.createdAt+''.toLowerCase().localeCompare(strB.createdAt+''.toLowerCase())
                              if (icmp != 0) { 
                                return icmp
                              } 
                              if (strA.createdAt > strB.createdAt) {
                                return 1
                              } else if (strA.createdAt < strB.createdAt) {
                                return -1
                              } else {
                                return 0
                              }}}
                            />
                            <Column className={styles.latest} title='วันที่ทำล่าสุด' dataIndex='latestTesting' render={(latestTesting) => {
                              return (
                                <span>{latestTesting ? moment.unix(latestTesting).format('DD/MM/YYYY') : '-'}</span>
                              )
                            }}
                            sorter={(a, b) => a.latestTesting - b.latestTesting }
                            />
                            <Column className={styles.status} align='center' title='สถานะ' dataIndex='status'
                              render= {(status, record) =>
                                <Switch
                                  checkedChildren='เปิด'
                                  unCheckedChildren='ปิด'
                                  checked={status === 'true' || status === true}
                                  onChange={(checked, e) => {
                                    e.stopPropagation()
                                    this.switchExamShow(record)
                                  }}
                                  className={styles.switch_exam}
                                />
                              }/>
                            <Column className={styles.share} render={(report,record,index) => {
                              const { checkReport, indexRow } = this.state
                              const mouserOver = () => { this.setState({ checkReport: true }) }
                              const mouseLeave = () => { this.setState({ checkReport: false}) }
                              return ( 
                                <img title='รายงาน' onClick={e => { e.stopPropagation();this.props.history.push(`/report/${record._id}/groups`,{ cameFrom: 'exam'}) }} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkReport && indexRow === index ? Report : ReportGray} style={{ cursor: 'pointer' }}/>
                              )
                            }}
                            />
                            <Column className={styles.share} render={(share,record,index) => {
                              const { checkShare, indexRow } = this.state
                              const mouserOver = () => { this.setState({ checkShare: true }) }
                              const mouseLeave = () => { this.setState({ checkShare: false}) }
                              return ( 
                                <Dropdown overlay={this.menu(record)} trigger={['click']}  onClick={e => e.stopPropagation()} title='แชร์'> 
                                  <span className='ant-dropdown-link actionIcon'>
                                    <img title='แชร์' onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={checkShare && indexRow === index ? Share : ShareGray} style={{ cursor: 'pointer' }}/>  
                                  </span> 
                                </Dropdown>
                              )
                            }}/>
                            <Column className={styles.delete} render={(deleteRow,record,index) => {
                              const { checkMouserOverDelete, indexRow } = this.state
                              const mouserOver = () => { this.setState({ checkMouserOverDelete: true }) }
                              const mouseLeave = () => { this.setState({ checkMouserOverDelete: false}) }
                              return ( 
                                <img title='ลบข้อสอบ' onClick={e=>{e.stopPropagation();this.onDeleteExamSet(record)}} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkMouserOverDelete && indexRow === index ? Delete : DeleteGray} style={{ cursor: 'pointer' }}/>
                              )
                            }}/>
                          </Table>
                          {
                            checkEnter && this.props.auth.role === 'student'? 
                              <div>
                                <h2 className = {styles.title_myexam}>ข้อสอบอื่น</h2>
                                <Divider/>
                                <Table rowKey = { (record,i) => i}
                                  dataSource={otherExamList.items}
                                  pagination={{ simple: true, defaultCurrent: 1, position: 'both', total : otherExamList ? otherExamList.totalCount : 0}}
                                  onRow = {(record, rowIndex) => {
                                    return {
                                      onMouseEnter: () => { this.setState({ indexRow: rowIndex}) },
                                      onClick: () => { this.props.history.push(`/examset/${record._id}`) }
                                    }
                                  }}
                                  className={styles.table_admin}
                                >
                                  <Column className={styles.suggest} title='ข้อสอบแนะนำ' dataIndex='isSuggestion'
                                    align='center' render={(isSuggestion,record,index) => {
                                      const { checkStar,indexRow } = this.state
                                      const mouseOver = () => {
                                        this.setState({ checkStar: true })
                                      }
                                      const mouserLeave = () => {
                                        this.setState({ checkStar: false })
                                      }
                                      return (
                                        <img onClick={e => {
                                          e.stopPropagation()
                                          isSuggestion ? this.onRemoveExamSuggestion(record) : this.checkExamSuggestion(myExamList.items)
                                        }}
                                        onMouseOver={mouseOver} onMouseLeave={mouserLeave}
                                        src={ isSuggestion? IconStar : (checkStar && indexRow === index ? IconStar :IconStarGray) }
                                        style={{ cursor: 'pointer' }}
                                        />
                                      )
                                    }}/>
                                  <Column className={styles.code} title='รหัสข้อสอบ' dataIndex='code' />
                                  <Column className={styles.name}
                                    title={() => {
                                      return ( <span style={{paddingLeft: '20px'}}>ชื่อ</span> )
                                    }} dataIndex='name' render={ (name,record) => {
                                      let logo = null
                                      if(record.type === 'G') {
                                        if(record.subject === 'คณิตศาสตร์') {
                                          logo = IconExamMath
                                        }else {
                                          logo = IconExamScience
                                        }
                                      }else if(record.type === 'CAT') {
                                        if(record.subject === 'คณิตศาสตร์') {
                                          logo = MathCATIcon
                                        }else {
                                          logo = ScienceCATIcon
                                        }
                                      }else{
                                        if(record.subject === 'คณิตศาสตร์') {
                                          logo = CompetitionMathIcon
                                        }else {
                                          logo = CompetitionScienceIcon
                                        }
                                      }
                                      return (
                                        <div><img src={logo}/>{name}</div>
                                      )
                                    }}/>
                                  <Column className={styles.type} title='ประเภท' dataIndex='type' render = {(type) => {
                                    return (
                                      <span>{ type === 'G' ? 'ตามหลักสูตร' : type === 'C' ? 'แข่งขัน' : type === 'CAT' ? 'CAT' : (type === 'EX' || type === 'E') ? 'แบบฝึกหัด' : 'กำหนดเอง' }</span>
                                    )
                                  }}/>
                                  <Column className={styles.counting} title= {() => {
                                    return (
                                      <span>
                                  จำนวนครั้งที่ทำ
                                      </span>
                                    )
                                  }} dataIndex = 'countTestings' align='center'/>
                                  <Column className={styles.createdBy} title = 'จัดโดย' dataIndex = 'owner' render = {(owner) => {
                                    return (
                                      <span>{owner.firstName+' '+owner.lastName}</span>
                                    )
                                  }}/>
                                  <Column className={styles.createdAt} title='วันที่สร้าง' dataIndex='createdAt' render={(createdAt) => {
                                    return (
                                      <span>{createdAt ? moment.unix(createdAt).add(543, 'Y').format('DD/MM/YYYY') : '-'}</span>
                                    )
                                  }}/>
                                  <Column className={styles.latest} title='วันที่ทำล่าสุด' dataIndex='latestTesting' render={(latestTesting) => {
                                    return (
                                      <span>{latestTesting ? moment.unix(latestTesting).format('DD/MM/YYYY') : '-'}</span>
                                    )
                                  }}/>
                                  <Column className={styles.status} align='center' title='สถานะ' dataIndex='status'
                                    render= {(status, record) =>
                                      <Switch
                                        checkedChildren='เปิด'
                                        unCheckedChildren='ปิด'
                                        checked={status === 'true' || status === true}
                                        onChange={(checked, e) => {
                                          e.stopPropagation()
                                          this.switchExamShow(record)
                                        }}
                                        className={styles.switch_exam}
                                      />
                                    }/>
                                  <Column className={styles.share} render={(report,record,index) => { 
                                    const { checkReport, indexRow } = this.state
                                    const mouserOver = () => { this.setState({ checkReport: true }) }
                                    const mouseLeave = () => { this.setState({ checkReport: false}) }
                                    return ( 
                                      <img title='รายงาน' onClick={e => { e.stopPropagation();this.props.history.push(`/report/${record._id}/groups`, {cameFrom: 'exam'}) }} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkReport && indexRow === index ? Report : ReportGray} style={{ cursor: 'pointer' }}/>
                                    )
                                  }}
                                  />
                                  <Column className={styles.share} render={(share,record,index) => {
                                    const { checkShare, indexRow } = this.state
                                    const mouserOver = () => { this.setState({ checkShare: true }) }
                                    const mouseLeave = () => { this.setState({ checkShare: false}) }
                                    return ( 
                                      <Dropdown title='แชร์' overlay={this.menu(record)} trigger={['click']}  onClick={e => e.stopPropagation()}> 
                                        <span title='แชร์' className='ant-dropdown-link actionIcon'>
                                          <img title='แชร์' onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={checkShare && indexRow === index ? Share : ShareGray} style={{ cursor: 'pointer' }}/>  
                                        </span> 
                                      </Dropdown>
                                    )
                                  }}/>
                                  <Column className={styles.delete} render={(deleteRow,record,index) => {
                                    const { checkMouserOverDelete, indexRow } = this.state
                                    const mouserOver = () => { this.setState({ checkMouserOverDelete: true }) }
                                    const mouseLeave = () => { this.setState({ checkMouserOverDelete: false}) }
                                    return ( 
                                      <img title='ลบข้อสอบ' onClick={e=>{e.stopPropagation();this.onDeleteExamSet(record)}} onMouseOver={mouserOver} onMouseLeave={mouseLeave} src={ checkMouserOverDelete && indexRow === index ? Delete : DeleteGray} style={{ cursor: 'pointer' }}/>
                                    )
                                  }}/>
                                </Table>
                              </div>
                              :
                              ''
                          }
                        </div>
                        :
                        <div>
                          {
                            checkEnter ?
                              <div>
                                <div className = {styles.div_result_title}>
                                  <span className = {styles.title_result_search}>ผลการค้นหา {`"${searchKey}"`} {sumQuantityOfSearch} รายการ </span>
                                  <a className = {styles.clear_result} style ={{color : '#333333'}} onClick={()=>this.clearSearch()}>เคลียร์การค้นหา</a>
                                </div>
                                <h2 className = {styles.title_myexam}>ข้อสอบของฉัน</h2>
                                <Divider/>
                              </div>
                              :
                              ''
                          }  
                          <Table
                            rowKey = { (record,i) => i}
                            columns={ this.columns }
                            dataSource={myExamList.items}
                            pagination={{simple: true, defaultCurrent: 1, position: 'both'}}
                            // onChange = {(pagination) => { this.onPaginate(pagination) }}
                            onRow={(examSet,rowIndex) => {   
                              return {
                                onMouseEnter: () => { this.setState({ indexRow: rowIndex}) 
                                }
                                ,
                                onClick: e => { 
                                  e.stopPropagation() 
                                  if(!hoverCodeExam) {
                                    if(examSet.group && examSet.group._id) { 
                                      return this.props.history.push(`/examset/${examSet._id}?groupId=${examSet.group._id}`,{groupId: myExamList.items[rowIndex].group._id})
                                    } else { 
                                      return this.props.history.push(`/examset/${examSet._id}`)
                                    }
                                  }
                                  
                                },
                              }
                            }}
                          />
                          {
                            checkEnter && this.props.auth.role === 'student' ?
                              <div>
                                <h2 className = {styles.title_myexam}>ข้อสอบอื่น</h2>
                                <Divider/>
                                <Table
                                  rowKey = { (record,i) => i}
                                  columns={ this.columns }
                                  dataSource={otherExamList.items}
                                  pagination={{ simple: true, defaultCurrent: 1, position: 'both'}}
                                  // onChange = {(pagination) => { this.onPaginate(pagination) }}
                                  onRow={(examSet,rowIndex) => {  
                                    return {
                                      onMouseEnter: () => { 
                                        this.setState({ indexRow: rowIndex})  
                                      },
                                      onClick: e => {
                                        e.stopPropagation()
                                        if(!hoverCodeExam) this.props.history.push(`/examset/${examSet._id}`)
                                      },
                                    }
                                  }}
                                />
                              </div>
                              :
                              ''
                          }
                        </div>
                    }

                  </div>
                </div>
              </Spin>

              <ModalShareToGroup
                isVisible={this.state.isShowShareToGroupModal}
                onAddExamSetToGroup={this.onSubmitShareToGroup}
                onCancel={this.onCloseModalShareToGroup}
                examSet={this.state.examSetToShare}
                // groupList={this.props.groupList}
                groupList={this.state.groupNotHaveExamList}
                onSearchGroupList={this.onSearchGroup}
              />

              <Modal
                visible={codeTestingPopup}
                onCancel={() => {
                  this.onCodeTestingPopup()
                  arrayCode = []
                }}
                footer={ null }
                centered
                bodyStyle={{ textAlign:'center'}}
                className= { styles.modal_exam_by_code }
                destroyOnClose = { true }
              >
                <div className={styles.modal_code_testing_warning} data-tutu = 'CreateExamsCodepTwo'>
                  <div >
                    <img className={styles.size_img_modal_code_testing} src={IconWarningTestByCodeTesting} alt='IconWarningTestByCodeTesting'/>
                  </div>
                  <h2>กรอกรหัสข้อสอบ</h2>
                  { block }
                  <div className={styles.bottom_modal} >
                    <span style={{ color: 'red' }}>{errorMassageSearchByCode}</span>
                  </div>
                  <div className={styles.bottom_modal}>
                    <Button loading={this.state.isLoadingCodeTesting} type='primary' key='startTesting' onClick={this.onSearchExamByCode}>ทำข้อสอบ</Button>
                  </div>
                </div>
              </Modal>

              <SearchModal
                isSearchModal={this.state.isSearchModal}
                showSearchModal={this.showSearchModal}
                title='ค้นหาข้อสอบ '
                placeholder='ชื่อ, รหัสข้อสอบ, จัดโดย'
                action={this.onSearchExamSet}
                centered
                destroyOnClose = { true }
              >
                {
                  searchResult.length > 0 ?
                    <div className={styles.examSetMobile}>
                      <ListItems
                        srcData = { searchResult }
                        avatar
                        status
                        // onPaginate = {this.onPaginate}
                        history={this.props.history}
                      />
                    </div>
                    : 
                    ''
                }
                {
                  this.props.auth.role === 'student' ?
                    <div className={styles.examSetMobile}>
                      <ListItems
                        srcData = { searchResultOther }
                        avatar
                        status
                        // onPaginate = {this.onPaginate}
                        history={this.props.history}
                      />
                    </div>
                    :
                    ''
                }
              </SearchModal>

              { (auth.role === USER_ROLE.STUDENT || auth.role === USER_ROLE.TEACHER) &&
              <ModalUpdatePrivacy 
                isVisible={showUpdatePrivacyNotice} 
                isStudent={auth.role === USER_ROLE.STUDENT}
                onOK={this.onCloseModalUpdateUserInfo}                
                isImportJoin={showWelcome}
              />
              }
                {(auth.role === USER_ROLE.STUDENT || auth.role === USER_ROLE.TEACHER) &&
                  (auth.isSeenTermAndCondition ?
                    <ModalPrivacyPolicy
                      key='ModalPrivacyPolicy'
                      isVisible={showModalPrivacyPolicy}
                      onOK={this.onSaveModalPrivacyPolicy}
                      onCancel={this.onCancelModalPrivacyPolicy}
                      role={auth.role}
                      dataPrivacy={dataPrivacy}
                      setPrivacyPolicy={this.setPrivacyPolicy}
                      fromaddgroup={false}
                    />
                    :
                    <ModalTermCondition
                      key='ModalTermCondition'
                      isVisible={showModalPrivacyPolicy}
                      onOK={this.onSaveModalPrivacyPolicy}
                      onCancel={this.onCancelModalPrivacyPolicy}
                      role={auth.role}
                      termAndCondition={termAndCondition}
                      dataPrivacy={dataPrivacy}
                      setPrivacyPolicy={this.setPrivacyPolicy}
                      fromaddgroup={false}
                      saveButtonLabel='บันทึก'
                    />
                  )}
            </div>
            </Fragment>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.profile,
    examSetList: state.exam.examSetList,
    groupList: state.group.groupList,
    dataPrivacy: state.master.dataPrivacy,
    termAndCondition: state.master.termAndCondition,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getExamSet,
      editExamSet,
      deleteExamSet,
      shareExamToGroup,
      getGroupList,
      searchExam,
      getExamSetList,
      onCheckExamSuggestion,
      removeExamSuggestion,
      getGroupNotHaveExam,
      getExamsAll,
      getStudentExamInExamSet,
      GetProfile
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(examSet)
