import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Form,
  Row,
} from 'antd'
import { 
  Link
} from 'react-router-dom'
import { generateInputForm } from '../../util/formGenerator'
import { IconValidate } from '../../components/IconSVG'
import { removeUndefinedProp } from '../../util/helper'
import { USER_ROLE } from '../../util/config'
import styles from './Register.module.scss'

const regExpContainAlphabet = /[a-zA-Z]+/
const regExpContainNumeric = /[0-9]+/ 

const formItemLayout = {
  colon: false,
  labelAlign: 'left',
  hideRequiredMark: true,
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
}

const ROLE_STUDENT = USER_ROLE.STUDENT
const ROLE_TEACHER = USER_ROLE.TEACHER

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env

class FormNewCredential extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isFormDirty: false,
      isPasswordMin6: false,
      isPasswordHaveAlphabet: false,
      isPasswordHaveNumberic: false,
      role: ROLE_STUDENT,
    }
  }

  async getToken() {
    let res = ""
    await window.grecaptcha.execute(REACT_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function(token) {
      // console.log(token)
      res = token
    })
    return res
  }

  componentDidMount() {
    const recaptchaScript = document.getElementById('recaptchaScript')
    if (!recaptchaScript) {
      const script = document.createElement('script')
      script.id = 'recaptchaScript'
      script.src = `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_SITE_KEY}`
      script.async = true
      document.body.appendChild(script)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      this.setState({ isFormDirty: true })
      if (!err) {
        const recaptchaToken = await this.getToken()
        const params = {
          ...values,
          role: this.state.role,
          recaptchaToken: recaptchaToken,
        }
        removeUndefinedProp(params)
        this.props.onSubmit(params)
      }
    })
  }

  switchRole = (role) => {
    if (role === this.state.role) return
    this.setState({
      role: role,
      isFormDirty: false,
    })
    this.props.form.resetFields()
  }

  matchPassword = (rule, value, callback) => {
    if ( value && value !== this.props.form.getFieldValue('password') ) {
      callback('รหัสผ่านยืนยันไม่ตรงกัน')
    }
    callback()
  }

  onChangePassword = (e) => {
    const value = e.target.value
    this.setState({
      isPasswordMin6: (value && value.length >= 6),
      isPasswordHaveAlphabet: value && regExpContainAlphabet.test(value),
      isPasswordHaveNumberic: value && regExpContainNumeric.test(value),
    })
    this.props.form.isFieldsTouched = false
  }

  render() {
    const { form } = this.props
    const{ isPasswordMin6, isPasswordHaveAlphabet, isPasswordHaveNumberic, isFormDirty } = this.state

    const isTeacher = this.state.role === ROLE_TEACHER

    // const inputUserName = isTeacher ? null : generateInputForm({ fieldName: 'username', form, label: 'ชื่อผู้ใช้งาน', isRegister: true, isFormDirty, limit: 30, customRules: [
    //   { required: true, message: 'กรุณาใส่ชื่อผู้ใช้งาน' },
    //   { min: 4, message: 'มีอย่างน้อย 4 ตัว' },
    //   { pattern: new RegExp(/^[A-Za-z0-9]+$/), message: 'ชื่อผู้ใช้งาน ต้องเป็นภาษาอังกฤษหรือตัวเลขเท่านั้น'},
    // ], props: {
    //   autoComplete: 'username'
    // } }) 
    const inputEmail = generateInputForm({ fieldName: 'email', form, label: 'อีเมล',isRegister: true, isFormDirty, limit: 254, customRules: [
      { required: true, message: 'กรุณาใส่อีเมล' },
      { type: 'email', message: 'รูปแบบอีเมลไม่ถูกต้อง' }
    ], props: {
      autoComplete: 'username'
    } })
    const inputPassword = generateInputForm({ fieldName: 'password', form, label: 'รหัสผ่าน', isFormDirty, limit: 20,isRegister: true , customRules: [
      { required: true, message: 'กรุณาใส่รหัสผ่าน' },
      { min: 6, message: 'มีอย่างน้อย 6 ตัว' },
      { pattern: new RegExp(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-_])[A-Za-z\d@$!%*#?&-_]{6,}$/) , message: 'ประกอบด้วยตัวอักษรภาษาอังกฤษและตัวเลข'},
    ], props: {
      type: 'password',
      autoComplete: 'new-password',
      onChange: this.onChangePassword
    } })
    const inputConfirmPassword = generateInputForm({ fieldName: 'confirm_password', form, label: 'ยืนยันรหัสผ่าน',isRegister: true , isFormDirty, limit: 20, customRules: [
      { required: true, message: 'กรุณายืนยันรหัสผ่าน' },
      { validator: this.matchPassword}
    ], props: {
      type: 'password',
      autoComplete: 'new-password',
    } })

    return (
      <Fragment>
        <div className='flex-space-between login-register' style={{ marginBottom: 15 }}>
          <h2 className={`${styles.formHead} text-medium mb-0`}>สมัครสมาชิก</h2>
          <Link className={`${styles.formLink} text-underline text-thin`} to='/login' >เข้าสู่ระบบ</Link>
        </div>

        <p className='text-medium'>ประเภทผู้ใช้งาน</p>

        <Row type='flex' justify='space-between' style={{ marginBottom: 15 }}>
          <Button id='studentRoleBtn' type={!isTeacher ? 'primary' : 'secondary'} className={!isTeacher ? styles.btnRole : styles.btnRoleSecondary} onClick={() => this.switchRole(ROLE_STUDENT)} >นักเรียน</Button>
          <Button id='teacherRoleBtn' type={ isTeacher ? 'primary' : 'secondary'} className={isTeacher ? styles.btnRole : styles.btnRoleSecondary} onClick={() => this.switchRole(ROLE_TEACHER)} >ครู/อาจารย์</Button>
        </Row>

        <Form {...formItemLayout} onSubmit={this.handleSubmit} >
          {/* {inputUserName} */}
          {inputEmail}
          <p className={`${styles.textValidate} text-thin text-sm`} style = {{marginTop : -10}}>(ระบบจะส่งข้อความให้ยืนยันตัวตนทางอีเมล)</p>
          {inputPassword}
          
          <p className={`${styles.textValidate} text-thin text-sm`} ><IconValidate correct={ isPasswordHaveAlphabet ? 1 : undefined } className={styles.iconValidate} /> ประกอบด้วยตัวอักษรภาษาอังกฤษ</p>
          <p className={`${styles.textValidate} text-thin text-sm`} ><IconValidate correct={ isPasswordHaveNumberic ? 1 : undefined } className={styles.iconValidate} /> ประกอบด้วยตัวเลข</p>
          <p className={`${styles.textValidate} text-thin text-sm`} ><IconValidate correct={ isPasswordMin6 ? 1 : undefined } className={styles.iconValidate} /> มีอย่างน้อย 6 ตัว</p>
          
          {inputConfirmPassword}
          <span style = {{color: 'red', fontSize: '16px'}}>* จำเป็นต้องกรอก</span>
          <Button id='nextStepRegister' type='primary' htmlType='submit' style={{ width: '100%', marginTop: 10 }}>
            ถัดไป
          </Button>
        </Form>
      </Fragment>
    )
  }
}

FormNewCredential.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

const WrappedFormNewCredential = Form.create({ name: 'form_new_credential' })(FormNewCredential)
export default WrappedFormNewCredential