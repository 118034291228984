import React, { Component, Fragment }  from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {
  Spin,
} from 'antd'
import CardLightMap from '../Display/CardLightMap'
import FormLogin from './FormLogin'
import {
  UserLogin
} from '../../redux/auth/action'
import ModalServerUnavailable from '../Server/ModalServerUnavailable'

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      errorLogin: null,
      isModalOffline: false
    }
  }

  setIsModalOffline = (value) => {
    this.setState({ isModalOffline: value })
  }

  onSubmitLogin = async (data) => { 
    this.setState({ isLoading: true })
    const res = await this.props.UserLogin({email: data.email, password: data.password, recaptchaToken: data.recaptchaToken})   
    if (res.error) {
      this.setState({ 
        isLoading: false,
        errorLogin: res.error,
      })
    } 
  }

  render() {
    const isAuthenticated = this.props.isAuthenticated
    const { serverStatus } = this.props
    return ( 
      !isAuthenticated ? 
        <Fragment>
          <CardLightMap>
            <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
              <FormLogin onSubmit={this.onSubmitLogin} 
                errorLogin={this.state.errorLogin} 
                serverStatus={serverStatus}
                />
            </Spin>
          </CardLightMap>
          <ModalServerUnavailable 
            serverStatus={serverStatus} 
            isModalOffline={this.state.isModalOffline} 
            setIsModalOffline={this.setIsModalOffline}  
          />
        </Fragment>
        :
        <Redirect to='/examset' />
    )
  }
}

const mapStateToProps = state => ({
  // Redirect after login
  isAuthenticated: state.auth.token ? true : false,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      UserLogin,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Login)