/* eslint-disable no-undef */
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Spin,
  message,
  Modal
} from 'antd'
import {
  GetContactDetail
} from '../../redux/master/action'
import {
  onSentFaqToUs
} from '../../redux/contact/action'
import { AxiosRequest } from '../../redux/fetch'
import styles from './LayoutGuest.module.scss'
import AddressIcon from '../IconSVG/AddressIcon.svg'
import TelephoneIcon from '../IconSVG/icon_tel.svg'
import FacebookIcon from '../IconSVG/icon_facebook_contact.svg'
import MailIcon from '../IconSVG/icon_mail.svg'
import { IconCorrect, IconIncorrect } from '../IconSVG'
import CardLightMap from '../Display/CardLightMap'

const { Option } = Select
const { TextArea } = Input
const API_RECAPTCHA_VERIFY = '/auth/recaptcha-verify'
const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env
class ContactUsComponent extends Component{
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      isLoading: false,
      contactDetail:'',
      checkEdit : false,
      faqData : {},
      optionTilte : [
        {value : 'สมัครสมาชิก', text : 'สมัครสมาชิก', key:'1'},
        {value : 'เข้าสู่ระบบ', text : 'เข้าสู่ระบบ', key : '2'},
        {value : 'คำถามอื่นๆ', text : 'คำถามอื่นๆ', key : '3'}
      ]
    }
  }

  componentDidMount(){
    window.scrollTo(0, this.myRef.current.offsetTop)
    this.fetchContactUsDetail()
  }

  extractEmail = (email) => {
    const emailLst = email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
    if (emailLst.length > 0) {
      return emailLst[0];
    }
    return null;
  }

  fetchContactUsDetail = async () => {
    this.setState({ isLoading: true })
    const res = await this.props.GetContactDetail()
    this.setState({ 
      faqData : {},
      isLoading: false,
      contactDetail : res,
      contactEmail: this.extractEmail(res.email)
    })
    if (res.error) message.error(res.error)
  }
  goToEditComponent = () =>{
    this.setState({
      checkEdit : !this.state.checkEdit
    })
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit(values)
      }
    })
  }
  setText = (value,name)=> {
    const {faqData} = this.state
    faqData[name] = value
    this.setState({
      faqData
    })
  }
  
  async getToken() {
    let res = ""
    await window.grecaptcha.execute(REACT_APP_RECAPTCHA_SITE_KEY, {action: 'login'}).then(function(token) {
      console.log(token)
      res = token
    })
    return res
  }
  
  onAdFaq = async (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll(async(err, values) => {   
      if(!err){ 
        this.setState({ isLoading: true })
        // BEGIN VERIFY RECAPTCHA v3
        const recaptchaToken = await this.getToken()
        const resRecaptcha = await AxiosRequest(API_RECAPTCHA_VERIFY, 'post', { recaptchaToken })
        if (resRecaptcha.result) {
          await this.props.onSentFaqToUs(values) 
          this.setState({ 
            isLoading: false 
          },()=>{
            const modal = Modal.success({
              closable: true,
              centered: true,
              icon: null,
              maskClosable: true,
              okButtonProps: {hidden: true},
              width: 220,
              content: (
                <div style={{textAlign: 'center'}}>
                  <IconCorrect style={{fontSize: 120}}/>
                  <h2 className='text-medium' style={{fontSize: 20}}>ส่งข้อความสำเร็จ</h2>
                </div>
              )
            })
            setTimeout(() => {
              modal.destroy()
            }, 2000)
          })        }
        else {
          await this.props.onSentFaqToUs(values) 
          this.setState({ 
            isLoading: false 
          },()=>{
            const modal = Modal.success({
              closable: true,
              centered: true,
              icon: null,
              maskClosable: true,
              okButtonProps: {hidden: true},
              width: 220,
              content: (
                <div style={{textAlign: 'center'}}>
                  <IconIncorrect style={{fontSize: 120}}/>
                  <h2 className='text-medium' style={{fontSize: 20}}>ระบบตรวจจับว่าคุณอาจจะเป็นโปรแกรมอัตโนมัติ</h2>
                </div>
              )
            })
            setTimeout(() => {
              modal.destroy()
            }, 2000)
          })
        }
        // END VERIFY RECAPTCHA v3        
      }
    })
  }

  render(){
    const {optionTilte} = this.state
    const { getFieldDecorator }  = this.props.form
    return(
      <div>
        <div className = {styles.divAboveContact}>
          <p className = {styles.titleContact}>{this.state.contactDetail ? this.state.contactDetail.name : '-' }</p>
        </div>
        <div className = {styles.divLeftContacUs} ref={this.myRef}>
          <Row>
            <Row >
              <Col sm = {1} xs = {2} style = {{marginRight : '1em'}}><img src = {AddressIcon} style = {{width : '17px'}}/></Col>
              <Col span={18}><label className = {styles.AddressInContac}>{this.state.contactDetail ? this.state.contactDetail.address : '' }</label></Col>
            </Row>
            <Row style = {{marginBottom : '1em'}}>
              <Col sm = {1} xs = {2} style = {{marginRight : '1em'}}><img src = {TelephoneIcon}/></Col>
              <Col span={18}><a className = {styles.linkInContact} href={this.state.contactDetail ? this.state.contactDetail.tel : '' }>{this.state.contactDetail ? this.state.contactDetail.tel : '' } </a></Col>
            </Row>
            <Row style = {{marginBottom : '1em'}}>
              <Col sm = {1} xs = {2} style = {{marginRight : '1em'}}><img src = {FacebookIcon}/></Col>
              <Col span={18}><a className = {styles.linkInContact} href='https://www.facebook.com/IPST.OnlineTesting/' rel='noopener noreferrer' target='_blank'>IPST.Online Testing</a></Col>
            </Row>
            <Row style = {{marginBottom : '1em'}}>
              <Col sm = {1} xs = {2} style = {{marginRight : '1em'}}><img src = {MailIcon}/></Col>
              <Col span={18}><a className = {styles.linkInContact} href={this.state.contactEmail ? 'mailto:'+this.state.contactEmail : '' }>{this.state.contactDetail ? this.state.contactDetail.email : '' }</a></Col>
            </Row>
          </Row>
        </div>
        <div className = {styles.divRightContactUs}>
          <CardLightMap >
            {/* <div className = {styles.contactForm}> */}
            <label className = {styles.titleSendMsgToUs}>ส่งข้อความหาเรา</label>
            <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
              
              <Form onSubmit={this.onAdFaq} hideRequiredMark={true}>
                <Form.Item label='ชื่อ'>
                  {
                    getFieldDecorator('name',{
                      rules: [
                        {
                          required: true,
                          min: 1,
                          message: 'กรุณากรอกชื่อ',
                        }
                      ],
                      initialValue: this.state.name
                    })(<Input placeholder= 'ชื่อผู้ใช้งาน' className ={styles.inputInContact}/>)
                  }
                </Form.Item>
                <Form.Item label='อีเมล'>
                  {
                    getFieldDecorator('email',{
                      rules: [
                        {
                          required: true,
                          min: 1,
                          type: 'email',
                          message: 'กรุณากรอกอีเมล',
                        },
                      ],
                      initialValue: this.state.email
                    })(<Input placeholder= 'อีเมล' className ={styles.inputInContact}/>)
                  }
                </Form.Item>
                <Form.Item label='หัวข้อคำถาม'>
                  {
                    getFieldDecorator('question',{
                      rules: [
                        {
                          required: true,
                          min: 1,
                          message: 'กรุณาเลือกหัวข้อคำถาม',
                        }
                      ]
                    })(<Select placeholder= 'กรุณาเลือก' 
                      className ={styles.inputInContact}
                      onChange = {(e)=> this.setText(e,'title')}
                    >
                      {                   
                        optionTilte.map((v, i) => {
                          return(
                            <Option value = {v.value} key = {i} >{v.text}</Option>  
                          )
                        })
                      }
                    </Select>)
                  }
                </Form.Item>
                <Form.Item label='ข้อความ'>
                  {
                    getFieldDecorator('text',{
                      rules: [
                        {
                          required: true,
                          min: 1,
                          message: 'กรุณาระบุข้อความ',
                        }
                      ]
                    })(
                      <TextArea className ={styles.inputInContact}
                        placeholder= 'รายละเอียด'
                        autosize={{ minRows: 4, maxRows: 6 }}
                      >                            
                      </TextArea>
                    )
                  }
                </Form.Item>
                <div style = {{textAlign : 'center'}}>
                  <Button htmlType='submit' type='primary'  id='' style={{ width: '100%', marginTop: 20 }}>
                ส่งคำถาม
                  </Button>
                </div>
              </Form>
          
            </Spin>
            {/* </div>   */}
          </CardLightMap> 
        </div>      
      </div>)
  }
}

const mapStateToProps = state => {
  return {
    contactDetail : state.master.contactDetail
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetContactDetail,
      onSentFaqToUs
    },
    dispatch
  )
const WrappedHorizontalLoginForm = Form.create({ name: 'contactGuest' })(ContactUsComponent)
export default connect(mapStateToProps, mapDispatchToProps)(WrappedHorizontalLoginForm)
