import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  message,
  Spin,
} from 'antd'
import CardLightMap from '../../components/Display/CardLightMap'
import FormNewCredential from './FormNewCredential'
import FormNewProfile from './FormNewProfile'
import FormParent from './FormParent'
import ModalTermCondition from './ModalTermCondition'
import RegisterSuccess from './RegisterSuccess'
import {
  GetMasterData
} from '../../redux/master/action'
import { AxiosRequest } from '../../redux/fetch'
import { removeUndefinedProp, formatSchoolObject } from '../../util/helper'
import { USER_ROLE } from '../../util/config'

const API_CHECK_USERNAME = '/check/is-username-exist'
const API_CHECK_EMAIL = '/check/is-email-exist'
const API_SCHOOL_DETAIL = '/master/schools'
const API_REGISTER = '/auth/register'

const ROLE_STUDENT = USER_ROLE.STUDENT
const ROLE_TEACHER = USER_ROLE.TEACHER

const API_RECAPTCHA_VERIFY = '/auth/recaptcha-verify'

class Register extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isRegisterSuccess: false,
      isLoading: false,
      schoolDetail: null,
      isShowTerm: false,
      registerParams: null,
      email: null,
      password: null,
      role: ROLE_STUDENT,
      username: null,
      privacyPolicy: null,
      provincesList : [],
      showFormProfile: false,
      showFormParent: false,
      age: 20,
    }
  }

  componentDidMount = async () => { 
    await this.props.GetMasterData() 
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.serverStatus.isOffline) {
      this.props.history.push('/login')
    }
    if(this.props.provinces !== nextProps.provinces){
      this.setState({  provincesList: nextProps.provinces})
    }
  }

  onSubmitNewCredential = async (data) => {
    this.setState({ isLoading: false })

    // BEGIN VERIFY RECAPTCHA v3
    const { recaptchaToken } = data
    const resRecaptcha = await AxiosRequest(API_RECAPTCHA_VERIFY, 'post', { recaptchaToken })
    let recaptchaCheck = false
    if (resRecaptcha.result) {
      recaptchaCheck = true
    }
    else {
      message.error('ระบบตรวจจับว่าคุณอาจจะเป็นโปรแกรมอัตโนมัติ')
    }
    // END VERIFY RECAPTCHA v3

    let check = false
    if (data.role === ROLE_TEACHER) {
      const res = await AxiosRequest(API_CHECK_EMAIL, 'get', data.email)
      if (res.result) {
        message.error('อีเมลนี้มีผู้ใช้งานแล้ว')
      } else {
        check = true  
      }
    } else {
      // FIX BUG 2022, There is no username anymore
      // if (data.username) {
      //   const res = await AxiosRequest(API_CHECK_USERNAME, 'get', data.username)
      //   if (res.result) {
      //     message.error('ชื่อผู้ใช้งานมีผู้ใช้งานแล้ว')
      //   } else {
      //     check = true  
      //   }
      // }
      if (data.email) {
        const res = await AxiosRequest(API_CHECK_EMAIL, 'get', data.email)
        if (res.result) {
          message.error('อีเมลนี้มีผู้ใช้งานแล้ว')
        } else {
          check = true  
        }
      }
    }

    if (check && recaptchaCheck) {
      this.setState({
        email: data.email,
        password: data.password,
        role: data.role,
        username: data.username,
      })
    }
    this.setState({ 
      isLoading: false,
      showFormProfile: true
    })
  }

  onSubmitNewProfile = async (data) => {
    const { email, password, role, username, privacyPolicy, age, birthYear } = this.state
    const { prefixName, firstName, lastName, profileImage, gender } = data 
    const params = {
      email,
      password,
      role,
      username,
      prefixName,
      firstName,
      lastName,
      profileImage,
      gender,
      privacyPolicy,
      birthYear,
      school: formatSchoolObject(data, this.state.schoolDetail),
      parent: {
        parentPrefix: '',
        parentFirstName: '',
        parentLastName: '',
        relation: '',
        parentEmail: '',
        parentTelephone: ''
      }
    }
    removeUndefinedProp(params)
    const minorAge = (age <= 10)

    this.setState({
      registerParams: params,
      showFormParent: minorAge,
      showFormProfile: !minorAge,
      isShowTerm: !minorAge,
      minorAge: minorAge
    })
  }

  onSubmitParent = async (data) => {
    const { registerParams } = this.state
    registerParams['parent'] = data
    this.setState({
      isShowTerm: true,
      registerParams
    })
  }

  onUpdateSchoolDetail = async (schoolId) => {
    // clear school detail on new school 
    if (!schoolId) return this.setState({schoolDetail: null})

    this.setState({ isLoading: true })
    const res = await AxiosRequest(API_SCHOOL_DETAIL, 'get', schoolId)
    this.setState({ isLoading: false })
    if (res.error) {
      this.setState({
        schoolDetail: null,
      })
      return message.error('ไม่สามารถรับข้อมูลโรงเรียนได้')
    } 
    this.setState({
      schoolDetail: res,
    })
  } 

  onUpdateBirthYear = (year) => {
    const d = new Date()
    const currentBEYear = d.getFullYear() + 543
    const age = currentBEYear - year
    this.setState({
      birthYear: year,
      age: age,
    })
  }

  onTermOK = async () => {
    const { registerParams,privacyPolicy }  = this.state
    registerParams.privacyPolicy = privacyPolicy
    if (!this.state.registerParams) return this.onTermCancel()
    
    await this.setState({
      isLoading: true,
      isShowTerm: false,
      registerParams
    }) 
    const res = await AxiosRequest(API_REGISTER, 'post', this.state.registerParams) 
    this.setState({ isLoading: false })
    if (res.error) return message.error(res.error || 'ไม่สามารถลงทะเบียนได้')
    this.setState({
      isRegisterSuccess: true,
    })
  }

  onTermCancel = () => {
    this.setState({
      isShowTerm: false,
      registerParams: null,
    })
  }

  onSuccessOK = () => {
    this.props.history.push('/login')
  }
  setPrivacyPolicy = (privacyPolicy) => {  
    this.setState({ privacyPolicy: privacyPolicy.target.checked })
  }
  render() {
    const { role } = this.state
    const { termAndCondition, dataPrivacy, provinces } = this.props 
    const { showFormParent, showFormProfile } = this.state
    return (
      <CardLightMap>
        <Spin size='large' tip='Loading...' spinning={this.state.isLoading} >
          { !this.state.isRegisterSuccess ? 
            (!(this.state.email || this.state.username) ? 
              <FormNewCredential onSubmit={this.onSubmitNewCredential} /> :
              [
                ( showFormProfile && <FormNewProfile  
                  key={i => i}
                  prefixes={this.props.prefixes} 
                  provinces={provinces}
                  schools={this.props.schools}
                  departments={this.props.departments}
                  onSubmit={this.onSubmitNewProfile}
                  onUpdateSchoolDetail={this.onUpdateSchoolDetail}
                  onUpdateBirthYear={this.onUpdateBirthYear}
                  schoolDetail={this.state.schoolDetail}
                  role={this.state.role} 
                />),
                ( showFormParent && <FormParent 
                  prefixes={this.props.prefixes} 
                  role={this.state.role}
                  relations={this.props.relations}
                  email={this.state.email}
                  onSubmit={this.onSubmitParent}
                />),
                <ModalTermCondition
                  key='ModalTermCondition'
                  isVisible={this.state.isShowTerm}
                  onOK={this.onTermOK}
                  onCancel={this.onTermCancel}
                  role={role}
                  termAndCondition={termAndCondition}
                  dataPrivacy={dataPrivacy}
                  setPrivacyPolicy = { this.setPrivacyPolicy }
                  fromaddgroup = {false}
                  saveButtonLabel="สร้างบัญชี"
                />
              ]
            ) :
            <RegisterSuccess onNext={this.onSuccessOK} haveEmail={this.state.email ? true : false} minorAge={this.state.minorAge} />
          }
          {/* {
            [
              <FormNewProfile  
                key={i => i}
                prefixes={this.props.prefixes} 
                provinces={provinces}
                schools={this.props.schools}
                departments={this.props.departments}
                onSubmit={this.onSubmitNewProfile}
                onUpdateSchoolDetail={this.onUpdateSchoolDetail}
                schoolDetail={this.state.schoolDetail}
                role={this.state.role} 
              />,
              <ModalTermCondition
                key='ModalTermCondition'
                isVisible={this.state.isShowTerm}
                onOK={this.onTermOK}
                onCancel={this.onTermCancel}
                role={role}
                termAndCondition={termAndCondition}
                dataPrivacy={dataPrivacy}
                setPrivacyPolicy = { this.setPrivacyPolicy }
              />
            ]
          } */}
        </Spin>
      </CardLightMap>
    )
  }

}

const mapStateToProps = state => {
  return {
    prefixes: state.master.prefixes,
    provinces: state.master.provinces,
    schools: state.master.schools,
    departments: state.master.departments,
    termAndCondition: state.master.termAndCondition,
    dataPrivacy: state.master.dataPrivacy,
    serverStatus: state.master.serverStatus,
    relations: state.master.relations,
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      GetMasterData,
    },
    dispatch
  )

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register))
